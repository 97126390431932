import React, { useContext, useRef, createRef, useState } from "react";
import styled from "styled-components";
import FullWidthSections from "./full-width-sections";
import BrandsWeOwn from "./brands-we-own";

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";

import { WebsiteContext } from "../../../context/WebsiteContext";
import { media } from "../../helpers";

import Slideshow from "../brands/slideshow";
import VideoBanner from "./video-banner";
import FullscreenImage from "../../elements/FullscreenImage";

const HomeContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};

  .title-container {
    text-align: center;
    padding-bottom: 50px;
    h2 {
      font-weight: 900;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      color: #222;
      font-size: 32px;
      ${media.medium`font-size: 40px;`};
    }
  }

  .all-logos-container {
    ${media.large`max-width: 1080px;`}
    .logo-section-container {
      padding-bottom: 70px;
      ${media.medium`padding-bottom: 100px;`}
      ${media.large`padding-bottom: 140px;`}
      .title {
        margin-bottom: 40px;
        h3 {
          font-weight: 900;
          margin: 0;
          text-transform: uppercase;
          color: #222;
          font-size: 21px;
          ${media.medium`font-size: 34px;`}
        }
      }

      .logo-grid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        ${media.medium`margin: 0 -20px;`}

        .icon-container {
          flex: 0 0 33.33%;
          margin-bottom: 20px;
          min-height: 100px;
          display: flex;
          align-items: center;
          ${media.small`flex: 0 0 25%;`};
          ${media.medium`flex: 0 0 16.66%;`};

          .image-container {
            max-width: 100px;
            margin: 0 auto;

            .gatsby-image-wrapper {
              max-height: 100px;

              img {
                object-fit: contain !important;
              }
            }

            button {
              background-color: #fff;
              border: none;
              padding: 0;

              > div {
              }
            }
          }
        }
      }
    }
  }
`;

const Home = ({ data }) => {
  let page = data?.home?.edges[0]?.node || {};
  let galleries = data?.galleries?.edges || [];
  let { fullPageScrollSection, brandsWeOwnSection, logoSectionsNew } =
    page?.home_acf || {};
  const [selectedGallery, setSelectedGallery] = useState([]);
  const [isSlideshowActive, setIsSlideshowActive] = useState(false);

  function toggleSlideshow(gallery) {
    setSelectedGallery(gallery);
    setIsSlideshowActive(true);
    // openLightbox();
  }

  const isBrowser = typeof window != "undefined";

  const { distanceFromTop } = useContext(WebsiteContext);

  const fsSections = useRef(fullPageScrollSection?.map(() => createRef()));
  let brandsWeOwnRef = useRef(null);

  function handleScrollToNextSection(index) {
    let scrollPoint = fsSections.current[index]
      ? fsSections.current[index].current.offsetTop - 77
      : brandsWeOwnRef.current.offsetTop - 177;

    if (isBrowser) {
      window.scrollTo({ top: scrollPoint, behavior: "smooth" });
    }
  }

  return (
    <HomeContainer distanceFromTop={distanceFromTop}>
      <VideoBanner />
      <FullWidthSections
        allRefs={fsSections}
        handleScrollToNextSection={handleScrollToNextSection}
        settings={fullPageScrollSection}
      />
      <BrandsWeOwn
        brandsWeOwnRef={brandsWeOwnRef}
        settings={brandsWeOwnSection}
      />
      <>
        <div className="title-container">
          <h2>Brands We Work With</h2>
        </div>
        <Wrapper className="all-logos-container">
          {logoSectionsNew.map((section, sectionIndex) => {
            const { sectionTitle, sectionIcons } = section || {};

            return (
              <div
                className={`logo-section-container`}
                key={`Logo Section ${sectionIndex}`}
              >
                <div className="title">
                  <h3>{sectionTitle}</h3>
                </div>

                <div className={`logo-grid ${sectionTitle.toLowerCase()}`}>
                  {sectionIcons?.map((icon, iconIndex) => {
                    let image = getImage(icon?.iconImage?.localFile);
                    let doesIconHaveLink = icon.brandUrl !== null;

                    if (doesIconHaveLink) {
                      return (
                        <div className="icon-container" key={iconIndex}>
                          <div className="image-container">
                            <a href={icon.brandUrl} target="_blank">
                              <GatsbyImage
                                image={image}
                                alt={`Logo ` + iconIndex}
                              />
                            </a>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="icon-container" key={iconIndex}>
                          <div className="image-container">
                            <GatsbyImage
                              image={image}
                              alt={`Logo ` + iconIndex}
                              objectFit="contain"
                            />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </Wrapper>

        {isSlideshowActive && (
          <Slideshow
            gallery={selectedGallery}
            setIsSlideshowActive={setIsSlideshowActive}
          />
        )}

        <FullscreenImage />
      </>
    </HomeContainer>
  );
};

export default Home;
