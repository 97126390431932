import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player/vimeo";
import { media } from "../../helpers";
import { StaticImage } from "gatsby-plugin-image";

const VideoStyles = styled.div`
  .banner__title {
    text-align: center;
    background-color: #f17230;
    color: #fff;
    padding: 30px 20px;
    text-transform: uppercase;
    background-size: cover;
    background-color: #ccc;
    position: relative;
    overflow: hidden;
    ${media.medium` padding: 40px 20px;`};

    .image {
      .gatsby-image-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
      }
    }

    .overlay {
      background: linear-gradient(
        to top,
        rgb(243, 132, 3, 0.5),
        rgba(243, 132, 3, 0)
      );
      height: 120px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    p {
      margin: 0px;
      line-height: 1.3;
      font-size: 26px;
      font-weight: 900;
      position: relative;
      z-index: 1000;

      ${media.medium`font-size: 42px; `};
    }
  }
  .banner__video {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const VideoBanner = () => {
  return (
    <VideoStyles>
      <div className="banner__title">
        <p>Fashion for all fans - passion for all brands</p>
        <div className="image">
          <StaticImage src="http://gregh35.sg-host.com/wp-content/uploads/2023/09/Asset_Medium-scaled.jpg" />
        </div>
        <div className="overlay"></div>
      </div>
      <div className="banner__video">
        <ReactPlayer
          url="https://player.vimeo.com/video/865618246?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          className="react-player"
          width="100%"
          height="100%"
          controls={false}
          playing
          muted
          loop
          playsinline
        />
      </div>
    </VideoStyles>
  );
};

export default VideoBanner;
