import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { TransitionMixin, media } from "../helpers";
import { Link } from "gatsby";

const FullscreenImageStyles = styled.div`
  overflow: hidden;
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 60vh;
  }

  img {
    object-position: center !important;
    max-height: 90vh;
    width: 100%;
    object-fit: cover !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    h3 {
      color: #fff;
      max-width: 1000px;
      font-size: 32px;
      text-align: center;
      text-transform: uppercase;
      ${media.medium`font-size: 42px;`}
    }

    .btn-container {
      text-align: center;
      a {
        color: #fff;
        text-decoration: none;
        border: 2px solid #fff;
        padding: 15px 10px;
        min-width: 220px;
        display: inline-block;
        text-transform: uppercase;
        line-height: 1;
        ${TransitionMixin(".25s")};
        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
`;

const FullscreenImage = ({ image }) => {
  return (
    <FullscreenImageStyles>
      <StaticImage
        src={
          "http://gregh35.sg-host.com/wp-content/uploads/2023/09/03.02.23_ConceptOne_FO_1410-scaled.jpg"
        }
        objectFit="contain"
      />
      <div className="overlay">
        <div className="content-container">
          <h3>
            We’re passionate about creating authentic and immersive product,
            through exceptional design and quality production.
          </h3>

          <div className="btn-container">
            <Link to={"/about"}>Learn More</Link>
          </div>
        </div>
      </div>
    </FullscreenImageStyles>
  );
};

export default FullscreenImage;
