import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { media, TransitionMixin } from "../../helpers";

import ChevronDown from "../../../images/chevron-down.inline.svg";
import { WebsiteContext } from "../../../context/WebsiteContext";

const FullWidthSectionsContainer = styled.div`
  .fs-section-container {
    position: relative;

    .image-container {
      max-height: ${props => `calc(100vh - ${props.distanceFromTop + "px"})`};
      overflow: hidden;

      .gatsby-image-wrapper {
        min-height: 75vh;

        ${media.large`min-height: ${props =>
          `calc(100vh - ${props.distanceFromTop + "px"})`};`}
      }
    }

    .slide-nav {
      position: absolute;
      right: 40px;
      top: 40%;
      display: none;
      ${media.small`display: block;`}
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          button {
            display: block;
            width: 0.6rem;
            height: 0.6rem;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: transparent;
            text-indent: -9999px;
            border: 1px solid #fff;
            ${TransitionMixin(".25s")}

            &:hover,
            &.active {
              background-color: #fff;
            }

            &:focus,
            &:active {
              outline: none;
            }
          }
        }
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(0 0 0 / 50%);
      width: 100%;
      height: 100%;
    }

    .content-container {
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;
      text-align: center;

      .inner-wrap {
        margin: 0 auto;
        padding: 0 20px;
        ${media.medium`max-width: 800px; `}
        ${media.large`max-width: 1000px; `}

        h2 {
          font-size: 28px;
          color: #fff;
          line-height: 1.2;
          text-transform: none;
          font-weight: 900;
          text-transform: uppercase;
          ${media.small`font-size: 32px;`}
          ${media.medium`font-size: 42px;`}
        }

        .btn-container {
          a {
            color: #fff;
            text-decoration: none;
            border: 2px solid #fff;
            padding: 15px 10px;
            min-width: 220px;
            display: inline-block;
            text-transform: uppercase;
            line-height: 1;
            ${TransitionMixin(".25s")};
            &:hover {
              background-color: #fff;
              color: #000;
            }
          }
        }
      }
    }
  }
  .footer-btn {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    text-align: center;

    button {
      line-height: 1;
      background-color: transparent;
      border: none;
      padding: 0;

      &:focus,
      &:active {
        outline: none;
      }

      svg {
        width: 60px;
        height: 60px;
        stroke: #fff;
        stroke-width: 1px;
      }
    }
  }
`;

const FullWidthSections = ({
  settings,
  handleScrollToNextSection,
  allRefs,
}) => {
  let rows = settings || [];

  const { distanceFromTop } = useContext(WebsiteContext);

  return (
    <FullWidthSectionsContainer distanceFromTop={distanceFromTop}>
      {rows.map((row, rowIndex) => {
        const { bannerLink, bannerHeadline } = row || {};
        let rowImage = getImage(row?.bannerImage?.localFile);

        return (
          <div
            className="fs-section-container"
            key={rowIndex}
            ref={allRefs?.current[rowIndex]}
          >
            <div className="image-container">
              <GatsbyImage
                alt={bannerHeadline + " banner image"}
                image={rowImage}
              />
              <div className="overlay"></div>
              <div className="content-container">
                <div className="inner-wrap">
                  <h2>{bannerHeadline}</h2>
                  <div className="btn-container">
                    <Link to={bannerLink.url}>{bannerLink.title}</Link>
                  </div>
                </div>
              </div>

              {/* <div className="footer-btn">
                <button onClick={() => handleScrollToNextSection(rowIndex + 1)}>
                  <ChevronDown />
                </button>
              </div> */}
            </div>
          </div>
        );
      })}
    </FullWidthSectionsContainer>
  );
};

export default FullWidthSections;
