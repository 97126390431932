import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Home from "../components/page-components/home";

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Home data={data} />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    galleries: allWpGallery {
      edges {
        node {
          id
          slug
          gallery_acf {
            gallery {
              galleryImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      width: 1000
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    home: allWpPage(filter: { title: { eq: "Home" } }) {
      edges {
        node {
          id
          home_acf {
            fullPageScrollSection {
              bannerHeadline
              bannerLink {
                title
                url
              }
              bannerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            brandsWeOwnSection {
              sectionTitle
              sectionBrands {
                brandUrl
                brandImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }

            logoSectionsNew {
              sectionTitle
              sectionIcons {
                iconImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        width: 300
                      )
                    }
                  }
                }
                brandUrl
              }
            }
          }
        }
      }
    }
  }
`;
