import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const BrandsWeOwnContainer = styled.div`
  padding: 100px 0;
  .brands-we-own-wrapper {
    .title-container {
      text-align: center;
      padding-bottom: 100px;
      h2 {
        font-weight: 900;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        color: #222;
        font-size: 32px;
        ${media.medium`font-size: 42px;`}
      }
    }
    .tiles-container {
      max-width: 1200px;
      .row {
        ${media.small`display: flex; flex-wrap: wrap;`}
        &.row-top {
          justify-content: center;
          margin-bottom: 30px;

          .item-container {
            ${media.medium`max-width: 370px;`}
          }
        }
      }

      .item-container {
        flex: 1;
        margin-bottom: 30px;
        padding: 0 15px;
        ${media.small`margin-bottom: 0px;`}

        a {
          width: 100%;
        }

        .inner-wrap {
          margin: 0 auto;
          ${media.small`display: flex;`}
        }
      }
    }
  }
`;

const BrandsWeOwn = ({ settings, brandsWeOwnRef }) => {
  const { sectionTitle, sectionBrands } = settings || {};
  return (
    <BrandsWeOwnContainer>
      {brandsWeOwnRef && <div ref={brandsWeOwnRef}></div>}
      <Wrapper className="brands-we-own-wrapper">
        <div className="title-container">
          <h2>{sectionTitle}</h2>
        </div>
        <Wrapper className="tiles-container">
          <div className="row row-top">
            {sectionBrands &&
              sectionBrands.slice(0, 2).map((brand, brandIndex) => {
                let brandImage = getImage(brand?.brandImage?.localFile);
                return (
                  <div className="item-container" key={brandIndex}>
                    <div className="inner-wrap">
                      <Link to={brand?.brandUrl}>
                        <div className="image-container">
                          <GatsbyImage
                            alt={`Brand Image ` + brandIndex}
                            image={brandImage}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row row-bottom">
            {sectionBrands &&
              sectionBrands
                .slice(2, sectionBrands.length)
                .map((brand, brandIndex) => {
                  let brandImage = getImage(brand?.brandImage?.localFile);
                  return (
                    <div className="item-container" key={brandIndex}>
                      <div className="inner-wrap">
                        <Link to={brand?.brandUrl}>
                          <div className="image-container">
                            <GatsbyImage
                              alt={`Brand Image ` + brandIndex}
                              image={brandImage}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
          </div>
        </Wrapper>
      </Wrapper>
    </BrandsWeOwnContainer>
  );
};

export default BrandsWeOwn;
